@import "../../../SCSS/colors.scss";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.my-designs-view-container {
  background-color: $greyLighter;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 30px 40px 0 40px;
  & .my-design-card-wrapper {
    width: 100%;
    padding: 10px 10px 10px 10px;
  }
}

@include media-breakpoint-up(lg) {
  .my-designs-view-container .my-design-card-wrapper {
    width: 50%;
  }
}
