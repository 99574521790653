$btnBorderWidth: 2px;
$btnThinerlBorderWidth: 1px;

.btn {
  background-color: transparent;
  border: none;
  padding: 8px 30px 8px 30px;
  font-size: 1rem;
  border-width: $btnBorderWidth;
  border-color: $grey;
  border-style: solid;
  border-radius: 50px;
  display: inline-block;
  color: $grey;
  margin: 5px 5px 5px 5px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.btn.btn-border-thin {
  border-width: $btnThinerlBorderWidth;
}

.btn:hover {
  background-color: $grey;
  color: $white;
  text-decoration: none;
}

.btn.btn-corporate {
  border-color: $red3;
  color: $red3;
}

.btn.btn-corporate:hover {
  background-color: $red3;
  color: $white;
}

.btn.btn-corporate.btn-filled {
  background-color: $red3;
  color: $white;
}

.btn.btn-corporate.btn-filled:hover {
  background-color: $white;
  color: $red3;
}

.btn.btn-white {
  border-color: $white;
  color: $white;
}

.btn.btn-white:hover {
  background-color: $white;
  color: $red3;
}

.btn.btn-with-icon {
  // width: 400px;
  // display: flex;
  justify-content: center;
  align-items: center;
}

.btn.btn-with-icon .icon {
  display: inline-block;
  width: 20px;
  vertical-align: sub;
  margin: 0 10px 0 0;
}

.btn.btn-with-icon .icon.normal-state {
  display: inline-block;
}

.btn.btn-with-icon .icon.hover-state {
  display: none;
}

.btn.btn-with-icon:hover .icon.normal-state {
  display: none;
}

.btn.btn-with-icon:hover .icon.hover-state {
  display: inline-block;
}

.btn.btn-with-icon .label {
  display: inline-block;
}
