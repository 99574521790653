@import "../../../SCSS/colors.scss";
@import "../../../SCSS/variables.scss";

.home-view-container {
  flex-grow: 1;
  width: 100%;
  max-width: $maxWidthApp;
  margin: 0 auto;
  flex: 1;
  display: flex;
  padding: 0 0 40px 0;
  flex-direction: column;
  & .segments-block-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    & .segment-block {
      flex: 1;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-items: center;
      position: relative;
      padding: 40px 10px 40px 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      margin: 0 3px 0 3px;
      &:after {
        content: "";
        position: absolute;
        top: -5%;
        left: 0;
        height: 110%;
        width: 100%;
        transform-origin: 0 0;
        transform: scaleY(0);
        opacity: 0;
        transition: all 0.4s cubic-bezier(0.53, 0.21, 0, 1);
        background-color: transparentize($red, 0.5);
      }
      &:hover {
        &:after {
          transform-origin: 100% 100%;
          opacity: 1;
          transform: scaleY(1);
        }
        & .segment-block__bg {
          transform: scale(1.05);
        }
      }
      & h1 {
        font-size: 1.8rem;
        font-weight: 500;
        position: relative;
        margin-top: -30px;
        &:before {
          content: "";
          background-color: $red3;
          height: 8px;
          width: 25%;
          position: absolute;
          left: 0;
          bottom: -30px;
          z-index: 10;
        }
      }
      &.block-link {
        transform: scale(1);
        position: relative;
        transition: all 0.2s ease;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0);
        text-align: center;
        color: $white;
        font-size: 2rem;
        line-height: 30px;
      }
      & img {
        width: 100%;
        max-height: 120px;
      }
      & .segment-block__title {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 1;
      }
      & .segment-block__bg {
        position: absolute;
        will-change: transform;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        max-height: none;
        object-fit: cover;
        object-position: center center;
        transform: scale(1);
        transition: transform 0.3s ease;
      }
    }
  }
}
