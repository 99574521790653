@import "../../../../../SCSS/colors.scss";

.configurator-menu-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.configurator-menu-container .primary-menu-btn {
  border: none;
  background-color: transparent;
  border-bottom: solid 1px $greyLight;
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px 10px 10px 10px;
  background-image: url("../../../../../imgs/breadcrumb-back-btn-right.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 15px;
}

.configurator-menu-container .primary-menu-btn:hover {
  background-color: transparentize($color: $greyLight, $amount: 0.5);
}

.configurator-menu-container .back-btn {
  border: none;
  background-color: transparent;
  background-image: url("../../../../../imgs/breadcrumb-back-btn.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 10px center;
  display: block;
  padding: 10px 20px 10px 40px;
  margin: 0 0 10px 0;
  width: 100%;
  text-align: left;
  background-color: $greyLight;
  font-weight: 700;
}

.configurator-menu-container .back-btn:hover {
  background-color: $darkGrey;
  color: $white;
}

.configurator-menu-container .level-menu {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translate3d(100%, 0px, 1px);
  -webkit-transform: translate3d(100%, 0px, 1px);
  -moz-transform: translate3d(100%, 0px, 1px);
  -ms-transform: translate3d(100%, 0px, 1px);
  -o-transform: translate3d(100%, 0px, 1px);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.configurator-menu-container .level-menu .btn-level-menu {
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  border-bottom: solid 1px $greyLight;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
}

.configurator-menu-container .level-menu .btn-level-menu.active {
  background-color: $greyLight;
}

.configurator-menu-container .level-menu .btn-level-menu:hover {
  background-color: transparentize($color: $greyLight, $amount: 0.5);
}

.configurator-menu-container .level-menu .btn-level-menu.with-arrow {
  background-image: url("../../../../../imgs/breadcrumb-back-btn-right.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  background-size: 15px;
}

.configurator-menu-container .level-menu .btn-level-menu .snap-container {
  width: 40px;
  height: 40px;
  background-color: $greyLight;
  overflow: hidden;
  border-radius: 80px;
  border: solid 1px $grey;
  margin-right: 15px;
}

.configurator-menu-container .level-menu .btn-level-menu .snap-container img {
  height: 100%;
  width: 100%;
}

.configurator-menu-container .level-menu.in {
  transform: translate3d(0%, 0px, 1px);
  -webkit-transform: translate3d(0%, 0px, 1px);
  -moz-transform: translate3d(0%, 0px, 1px);
  -ms-transform: translate3d(0%, 0px, 1px);
  -o-transform: translate3d(0%, 0px, 1px);
  // opacity: 1;
  // visibility: visible;
}

.configurator-menu-container .level-menu .level-menu-wrapper {
  height: 100%;
}
