@import "../../../SCSS/colors.scss";

.small-spinner-loader,
.small-spinner-loader:after {
  border-radius: 50%;
  width: 10em;
  display: inline-block;
  height: 10em;
}
.small-spinner-loader {
  margin: 0 auto;
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid transparentize($red3, 0.8);
  border-right: 1.1em solid transparentize($red3, 0.8);
  border-bottom: 1.1em solid transparentize($red3, 0.8);
  border-left: 1.1em solid $red3;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: small-spinner-animation 1.1s infinite linear;
  animation: small-spinner-animation 1.1s infinite linear;
}
@-webkit-keyframes small-spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes small-spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
