@import "../../../SCSS/colors.scss";

.version-badge-container {
  color: $black;
  background-color: $greyLight;
  font-weight: 700;
  font-size: 0.6rem;
  padding: 5px 8px 5px 8px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
