.bg-video-container {
}

.bg-video-container video {
  width: 100%;
  height: 100%;
}
.bg-video-container.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
