@import "../../../SCSS/colors.scss";

.drawer-menu-collections {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: red;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  // justify-content: stretch;
  align-items: flex-end;
  transition: all 0.3s ease;
  z-index: 100;
}

.drawer-menu-collections.active {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.drawer-menu-collections .drawer-menu-wrapper {
  max-width: 400px;
  width: 100%;
  // border: solid 1px red
  position: relative;
  padding: 50px 20px 0 20px;
  background-color: #fff;
  height: 100%;
  transform: translate3d(100%, 0px, 1px);
  transition: all 0.3s ease;
  z-index: 10;
}

.drawer-menu-collections .drawer-menu-wrapper .close-btn {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
}

.drawer-menu-collections .drawer-menu-wrapper .menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.drawer-menu-collections .drawer-menu-wrapper .menu li {
  display: block;
}

.drawer-menu-collections .drawer-menu-wrapper .menu li button {
  display: block;
  border: none;
  background-color: transparent;
  padding: 10px 0 10px 0;
  border-bottom: solid 1px $greyLight;
  width: 100%;
}

.drawer-menu-collections.active .drawer-menu-wrapper {
  transform: translate3d(0%, 0px, 1px);
}

.drawer-menu-collections .drawer-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  // background-color: red;
  visibility: hidden;
}

.drawer-menu-collections.active .drawer-bg {
  visibility: visible;
}
