@import "../../../SCSS/colors.scss";
@import "../../../SCSS/variables.scss";

.configurator-view-container {
  padding: 0 0 20px 0;
  border-top: solid 1px $greyLightMid;
  max-width: $maxWidthApp;
  margin: 0 auto;
}
.configurator-view-container .row-top {
  display: flex;
  margin: 0 0 20px 0;
  padding: 40px 0 0 0;
}

.configurator-view-container .row-top .loading-product {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  visibility: visible;
  opacity: 1;
  color: black;
  // justify-content: center;
  align-items: center;
  // border: solid 1px red;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  z-index: 30;
}

.configurator-view-container .row-top .loading-product.done {
  opacity: 0;
  visibility: hidden;
}

.configurator-view-container .row-top .col-2 {
  width: 55%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.configurator-view-container .row-top .col-2.col-right {
  padding: 0 10px 0 40px;
  width: 45%;
}

.configurator-view-container .row-bottom {
  padding: 30px 0 0 0;
}

#renderer-cont {
  width: 100%;
  height: 700px;
  position: relative;
}

#renderer-cont.ar-mode {
  position: absolute;
  z-index: 100;
}

#renderer-cont.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
