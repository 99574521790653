@import "../../../../../SCSS/colors.scss";

.product-info-container {
}

.product-info-container h1 {
  font-size: 1.3rem;
  font-weight: 700;
  color: $darkGrey;
  margin: 0 0 30px 0;
}

.product-info-container .description {
  margin: 0 0 30px 0;
}
