@import "../../../../../SCSS/colors.scss";

.more-info-container {
  & .btns-group {
    display: flex;
    align-items: bottom;
    justify-content: flex-start;
    margin: 0 0 60px 0;
    & .tech-and-related-btns-container {
      border-bottom: solid 1px $greyLight;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 20px 0 20px;
      & button {
        border: none;
        background-color: transparent;
        display: inline-block;
        position: relative;
        padding: 10px 20px 10px 20px;
        height: 100%;
        &.active {
          &:after {
            opacity: 1;
          }
        }
        &:hover {
          &:after {
            opacity: 1;
          }
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          background-color: $red3;
          height: 3px;
          width: 100%;
          left: 0;
          opacity: 0;
        }
      }
    }
  }
}
