@import "./colors.scss";

.bg-grey-blue-light {
  background-color: $GreyBlueLight;
}

.bg-grey-lighter {
  background-color: $greyLighter;
}

.bg-grey-light {
  background-color: $greyLight;
}

.bg-grey {
  background-color: $grey;
}

.bg-red {
  background-color: $red3;
}

.bg-dark-grey {
  background-color: $darkGrey;
}

.bg-dark-grey-lighter {
  background-color: $darkGrey;
}

.bg-transparent {
  background-color: transparent;
}
