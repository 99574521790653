@import "../../../../../SCSS/colors.scss";

.my-design-card-container {
  display: flex;
  background-color: $white;
  border: solid 1px $greyLight;
  align-items: center;
  box-shadow: 0px 2px 3px transparentize($black, 1);
  transition: all 0.2s ease;
  &:hover {
    border: solid 1px $greyLightMid;
    box-shadow: 0px 0px 10px transparentize($black, 0.8);
  }
  &.my-design-card__obsolete {
    background-color: transparentize($greyLightMid, 0.9);
    &.bg-red {
      background-color: $red3;
    }
    & .title-my-design-container {
      opacity: 0.5;
    }
    & .product-name-and-date-container {
      opacity: 0.5;
    }
    & .snap-container {
      filter: grayscale(1);
    }
  }
  & h4 {
    font-size: 1rem;
  }
  & .title-my-design-container {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px $greyLight;
    margin: 0 0 10px 0;
    & h3 {
      font-size: 1rem;
    }
    & .edit-title-btn {
      border: none;
      background-color: transparent;
      width: 30px;
    }
  }
  & .product-name-and-date-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;
    & .date {
      font-weight: 100;
      font-size: 0.8rem;
    }
  }
  & .snap-container {
    width: 20%;
    max-width: 400px;
    min-width: 200px;
  }
  & .info-container {
    padding: 10px 10px 10px 10px;
    flex: 1;
    & .product-name {
      font-weight: 100;
      margin: 0;
    }
    & .input-edit-title {
      width: 100%;
      display: inline-block;
      margin: 0 0 0 5px;
    }
  }
  & .group-action-btns {
    align-items: center;
    display: flex;
    & :last-child {
      flex: 1;
      text-align: right;
    }
    & .action-btn {
      border: none;
      background-color: transparent;
      border: solid 2px $grey;
      border-radius: 50px;
      width: 45px;
      height: 45px;
      text-align: center;
      line-height: 0px;
      display: inline-block;
      margin: 0 5px 0 5px;
      &:hover {
        border: solid 2px $red3;
        & .icon {
          &.icon-hover {
            display: inline;
          }
          &.icon-normal {
            display: none;
          }
        }
      }
      & .icon {
        width: 20px;
        &.icon-hover {
          display: none;
        }
      }
    }
  }
}
