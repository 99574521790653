@import "../../../../../SCSS/colors.scss";

.technical-specs-container {
  & .col {
    &.col-tech-info {
      width: 40%;
      padding: 0 30px 0 0;
    }
    &.col-tech-image {
      width: 60%;
      padding: 0 0 0 30px;
    }
  }
  & .techincal-specs-list {
    list-style: none;
    margin: 0;
    padding: 0;
    & li {
      display: block;
      margin: 0 0 20px 0;
      position: relative;
      padding: 0 0 0 20px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 10px;
        height: 10px;
        border-radius: 50px;
        background-color: $red3;
      }
    }
  }
}
