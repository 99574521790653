.main-template-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  & .children-container {
    padding: 0 40px 0 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
