@import "../../../SCSS/colors.scss";

.main-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
    & li {
      display: inline-block;
      position: relative;
      &.contact-btn-wrapper {
        & button {
          border: none;
          background-color: transparent;
          transform: scale(0.8);
          transition: all 0.3s ease;
          &:hover {
            transform: scale(1);
          }
          & img {
            width: 35px;
          }
        }
      }
      & a {
        display: block;
        padding: 20px 10px 20px 10px;
        color: $darkGrey;
        text-decoration: none;
        font-weight: 700;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: 10px;
          left: 25%;
          width: 50%;
          height: 2px;
          background-color: $darkGrey;
          transform: scale(0);
          transition: all 0.2s ease;
        }
        &:hover {
          &:after {
            transform: scale(1);
          }
        }
      }
      &.my-design-menu-element-cont {
        border: solid 1px transparent;
        position: relative;
        & .badge-my-designs-quantity {
          background-color: $red3;
          color: $white;
          // font-weight: 100;
          display: inline-block;
          font-size: 0.7rem;
          padding: 3px 6px 3px 6px;
          position: absolute;
          top: 8px;
          right: -10px;
          letter-spacing: 1px;
          border-radius: 50px;
        }
        & .my-designs-menu-toastr {
          position: absolute;
          top: 0;
          left: -400px;
          width: 400px;
          height: 100%;
          background-color: $green;
          opacity: 0;
          visibility: hidden;
          color: $white;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.active {
          border: solid 1px $green;
          & .my-designs-menu-toastr {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
