@import "../../../SCSS/colors.scss";

.topbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $greyLightMid;
  padding: 20px 40px 20px 40px;
  & .logo {
    max-width: 300px;
    width: 300px;
  }
}
