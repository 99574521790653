@import "../../../SCSS/colors.scss";

.pagination-container .pagination .page-item a {
  display: inline-block;
  background-color: $greyLight;
  margin: 0 3px 0 3px;
  border-radius: 20px;
  font-size: 0.8rem;
}

.pagination-container .pagination .page-item a:hover {
  background-color: $darkGrey;
  color: $white;
}

.pagination-container .pagination .page-item.page-number a {
  width: 40px;
}

.pagination-container .pagination .page-item.page-number.active a {
  background-color: $red3;
  color: $white;
}
