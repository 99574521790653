.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.modal.active {
  opacity: 1;
  visibility: visible;
}

.modal .content-wrapper {
  background-color: #fff;
  z-index: 1;
  // padding: 10px 10px 10px 10px;
  border-radius: 5px;
  overflow: hidden;
  width: 80%;
  max-width: 800px;
  // height: 90%;
  max-height: 100vh;
  overflow-y: scroll;
}

.modal .header-modal {
  position: relative;
  padding: 30px 0 30px 0;
}

.modal .header-modal h2 {
  font-size: 1.3rem;
}

.modal .header-modal .close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal .modal-body {
  padding: 20px 20px 20px 20px;
}

.modal .modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
