$corporate-color: #272727;
$red: #d80022;
$red2: #b5002c;
$red3: rgb(219, 33, 48);
$greyLighter: #f0f0f0;
$greyLight: #e9e9e9;
$grey: #717171;
$greyLightMid: #ccc;
$darkGreyLighter: #585858;
$darkGrey: #393939;
$greyDarkest: #232324;
$GreyBlueLight: #e0e8e8;
$white: #fff;
$black: #000;
$green: rgb(145, 171, 52);
