@import "../../../SCSS/colors.scss";

.breadcrumb-container {
  & .back-btn {
    width: 25px;
    display: inline-block;
    padding: 0;
    border: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  & .breadcrumb-list {
    margin: 0;
    padding: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & li {
      display: inline-block;
      margin: 0 5px 0 5px;
      & a {
        font-weight: 100;
        color: $darkGrey;
        text-decoration: none;
        &:hover {
          color: $red3;
        }
      }
      &:last-child a {
        font-size: 2.3rem;
      }
    }
  }
}
