@import "../../../SCSS/colors.scss";
@import "../../../SCSS/variables.scss";

.products-list-template-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: $maxWidthApp;
  margin: 0 auto;
}

.products-list-template-container .products-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
