@import "../../../SCSS/colors.scss";

.product-card {
  width: 100%;
  max-width: 250px;
  background-color: $greyLight;
  position: relative;
  margin: 2px;
  padding: 50px 15px 50px 15px;
  box-sizing: border-box;
}

.product-card img {
  display: block;
  max-width: 100%;
}

.product-card .hover-info-product {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 20px 20px 20px;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba($red3, 0.5);
  color: $white;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.product-card .hover-info-product h1 {
  font-size: 1rem;
  margin: 0;
}

.product-card:hover .hover-info-product {
  visibility: visible;
  opacity: 1;
}
