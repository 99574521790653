.container-login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-login input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 5px;
}
