@import "../../../SCSS/variables.scss";

.bottom-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: $maxWidthApp;
  margin: 0 auto 0 auto;
  width: 100%;
  padding: 30px 40px 35px 40px;
  & .search-and-drawer-cont {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
