@import "../../../../../SCSS/colors.scss";

.render-action-menu-container {
  position: absolute;
  top: 0;
  // width: 50px;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 10px;
}

.render-action-menu-buttons-wrapper button {
  display: block;
  pointer-events: initial;
  border: none;
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  filter: grayscale(100%);
  cursor: pointer;
  border-bottom: solid 1px $greyLight;
}

.render-action-menu-buttons-wrapper button[disabled] {
  opacity: 0.3;
}

.render-action-menu-buttons-wrapper button img {
  width: 25px;
  opacity: 0.5;
}

.render-action-menu-buttons-wrapper button:hover {
  filter: grayscale(0%);
}

.render-action-menu-buttons-wrapper button:hover img {
  opacity: 1;
}
