// @import url("https://fonts.googleapis.com/css?family=Oxygen:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import "./colors.scss";

@mixin corporateFont {
  // font-family: "Oxygen", sans-serif;
  font-family: "Ubuntu", sans-serif;
}

.text-black {
  color: $black;
}

.text-red {
  color: $red3;
}

.text-white {
  color: $white;
}

.text-grey-dark {
  color: $darkGrey;
}

.font-light {
  font-weight: 100;
}

.font-bold {
  font-weight: 700;
}
